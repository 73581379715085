import React, { useContext } from "react"
import { Link } from "gatsby"
import trattamentoCardStyles from "../../styles/cards/trattamento-card.module.scss"
import Img from "gatsby-image"
import AddToCartIcon from "../../assets/addToCart.svg"
import { formatPrice } from "../../utilities/formatPrice"
import { StoreContext } from "../../context/storeContext"
import { isDateEarlier, formatDate } from "../../utilities/dateUtils"

const TrattamentoCard = ({ post }) => {
  const { addToCommittedCart } = useContext(StoreContext)
  const today = new Date().toLocaleDateString("it-IT", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  })
  const isTrattamentoValid =
    post.node.foto &&
    post.node.nome &&
    post.node.slug &&
    post.node.prezzo &&
    post.node.durata &&
    post.node.categoria

  if (isTrattamentoValid) {
    return (
      <div className={trattamentoCardStyles.cardBorderWrap}>
        {post.node.promo &&
          isDateEarlier(formatDate(post.node.promo.inizio), today) &&
          isDateEarlier(today, formatDate(post.node.promo.fine)) && (
            <div className={trattamentoCardStyles.promo}>
              <span className={trattamentoCardStyles.promoText}>
                {post.node.promo.codice} sconto {post.node.promo.sconto}€
              </span>
            </div>
          )}
        <div className={trattamentoCardStyles.card}>
          <Img
            style={{ height: "30rem" }}
            className={trattamentoCardStyles.image}
            fluid={post.node.foto.childImageSharp.fluid}
            alt={`foto natura - ${post.node.foto.name}`}
          />
          <h4 className={trattamentoCardStyles.title}>{post.node.nome}</h4>
          <span className={trattamentoCardStyles.category}>
          {(`${post.node.categoria.replace(/_/g, " ")}` === 'pacchetto') ? 'percorso' : `${post.node.categoria.replace(/_/g, " ")}`}
          </span>
          <div className={trattamentoCardStyles.header}>
            <span className={trattamentoCardStyles.price}>
              {formatPrice(post.node.prezzo)}
            </span>
            <span className={trattamentoCardStyles.duration}>
              {post.node.durata}
            </span>
          </div>
          {post.node.categoria !== "gift_card" && (
            <div className={trattamentoCardStyles.headerBtn}>
              <Link
                className={trattamentoCardStyles.details}
                to={`/trattamenti/${post.node.slug}`}
              >
                {" "}
                dettagli{" "}
              </Link>
              <button
                className={trattamentoCardStyles.cart}
                onClick={() => addToCommittedCart(post.node)}
              >
                <AddToCartIcon className={trattamentoCardStyles.icon} />
              </button>
            </div>
          )}
          {post.node.categoria === "gift_card" && (
            <div className={trattamentoCardStyles.headerBtn}>
              <button
                style={{ width: "90%" }}
                className={trattamentoCardStyles.cart}
                onClick={() => addToCommittedCart(post.node)}
              >
                <AddToCartIcon className={trattamentoCardStyles.icon} />
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default TrattamentoCard
